import React, { useState } from "react";
import axios from "axios";
import img1 from "../src/klipartz.com.png";
import img2 from "../src/pngwing.com.png";
import "./App.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

function Peticion() {
  const [h2, setH2] = useState("");
  const [ch4, setCh4] = useState("");
  const [c2h6, setC2h6] = useState("");
  const [c2h4, setC2h4] = useState("");
  const [c2h2, setC2h2] = useState("");
  const [prediction, setPrediction] = useState("");
  const [recomendacion, setRecomendacion] = useState("");
  const [serieTransformador, setSerieTransformador] = useState("");
  const [fechaPrueba, setFechaPrueba] = useState("");
  const [salida, setSalida] = useState("");
  const [edadTransformador, setEdad] = useState("");

  const [salidaEdad, setSalidaedad] = useState("");

  const [plot, setPlot] = useState("");
  const [plot2, setPlot2] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://transformador.onrender.com/predict", {
        h2: parseFloat(h2),
        ch4: parseFloat(ch4),
        c2h6: parseFloat(c2h6),
        c2h4: parseFloat(c2h4),
        c2h2: parseFloat(c2h2),
        fechaPrueba: Date(fechaPrueba),
        serieTransformador: serieTransformador,
        edadTransformador: edadTransformador,
      });
      const responseData = response.data;
      console.log(responseData);

      setPrediction(responseData.prediction);
      setRecomendacion(responseData.recommendation);
      setFechaPrueba(responseData.fecha_prueba);
      setSalida(responseData.serie_transformador);
      setSalidaedad(responseData.edad_transformador);
      setPlot(responseData.plot);
      setPlot2(responseData.plot2);

      // Limpiar campos de entrada
      setH2("");
      setCh4("");
      setC2h6("");
      setC2h4("");
      setC2h2("");
      setSerieTransformador("");
      setEdad("");
    } catch (error) {
      console.error("Error al obtener la predicción:", error);
    }
  };
  //const formattedFechaPrueba = fechaPrueba ? fechaPrueba.split('-').reverse().join('/') : '';
  //console.log("fecha",formattedFechaPrueba)
  const generarPDF = () => {
    const input = document.getElementById("pdf-content");
    input.classList.remove('hide');
    html2canvas(input, { scale: 2 }) // Aumentar la escala para mejorar la calidad de la imagen
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png", 1.0); // Establecer la calidad de la imagen al máximo
        const pdf = new jsPDF();
        const imgWidth = 210;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

        // Abrir PDF en una nueva pestaña
        const pdfURL = pdf.output("bloburl"); // Convertir el PDF a una URL de blob
        window.open(pdfURL, "_blank");
      })
      .catch((error) => {
        console.error("Error al generar PDF:", error);
      }).finally(()=>{
        input.classList.add('hide');
      });
  };

  return (
    <div className="bg-slate-300 p-4">
    <div className="border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
        <div className="flex items-center justify-center rounded bg-gray-50 dark:bg-gray-800">
          {plot ? (
            <img src={`data:image/png;base64,${plot}`} alt="Duval Triangle" />
          ) : (
            <img src={img1} alt="Default" />
          )}
        </div>
        <div className="flex items-center justify-center rounded bg-gray-50 dark:bg-gray-800">
          <form className="w-full max-w-lg" onSubmit={handleSubmit}>
            <p className="text-slate-950 text-center text-lg sm:text-xl italic">
              Sistema para el Análisis de gases disueltos para el mantenimiento preventivo de transformadores de potencia
            </p>
            <br />
            <div className="flex flex-wrap-mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="h2">
                  H2 (Hidrógeno)
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-slate-950 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="h2"
                  type="number"
                  value={h2}
                  onChange={(e) => setH2(e.target.value)}
                  required
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="ch4">
                  CH4 (Metano)
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-slate-950 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  id="ch4"
                  type="number"
                  value={ch4}
                  onChange={(e) => setCh4(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="flex flex-wrap-mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="c2h6">
                  C2H6 (Etano)
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-slate-950 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="c2h6"
                  type="number"
                  value={c2h6}
                  onChange={(e) => setC2h6(e.target.value)}
                  required
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="c2h4">
                  C2H4 (Etileno)
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-slate-950 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  id="c2h4"
                  type="number"
                  value={c2h4}
                  onChange={(e) => setC2h4(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="flex flex-wrap-mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="c2h2">
                  C2H2 (Acetileno)
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-slate-950 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="c2h2"
                  type="number"
                  value={c2h2}
                  onChange={(e) => setC2h2(e.target.value)}
                  required
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="serie">
                  # Serie
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-slate-950 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  id="serie"
                  type="number"
                  value={serieTransformador}
                  onChange={(e) => setSerieTransformador(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-wrap-mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="edad">
                  Edad del transformador
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-slate-950 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="edad"
                  type="number"
                  value={edadTransformador}
                  onChange={(e) => setEdad(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-wrap-mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                  Calcular
                </button>
              </div>
              <div className="w-full md:w-1/2 px-3">
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={generarPDF}>
                  Descargar PDF
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="flex items-center justify-center rounded bg-gray-50 dark:bg-gray-800">
          {plot2 ? (
            <img src={`data:image/png;base64,${plot2}`} alt="Duval Triangle" />
          ) : (
            <img src={img2} alt="Default" />
          )}
        </div>
      </div>
      <div className="text-center">
        <p className="text-slate-950 text-xl italic" key={`prediction-${prediction}`}>
          Diagnóstico: {prediction}
        </p>
        <p className="text-slate-950 text-xl italic" key={`recommendation-${recomendacion}`}>
          Nota agregada: {recomendacion}
        </p>
        <p className="text-slate-950 text-xl italic" key={`fechaPrueba-${fechaPrueba}`}>
          Fecha: {fechaPrueba}
        </p>
        <p className="text-slate-950 text-xl italic" key={`serieTransformador-${salida}`}>
          #Serie: {salida}
        </p>
        <p className="text-slate-950 text-xl italic" key={`salidaEdad-${salidaEdad}`}>
          Edad: {salidaEdad}
        </p>
      </div>
      <div id="pdf-content" className="overflow-y-auto hide">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <tbody>
            
          <tr>
        <td colSpan="2" className="text-center py-4">
          <p className="text-slate-950 text-center text-4xl italic">
            Informe del diagnóstico realizado al transformador
          </p>
        </td>
      </tr>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th scope="row" className="px-8 py-6 text-6xl font-medium text-gray-900 whitespace-nowrap dark:text-white">
                Diagnóstico:
              </th>
              <td className="px-8 py-6 text-6xl" key={`prediction-${prediction}`}>
                {prediction}
              </td>
            </tr>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th scope="row" className="px-8 py-6 text-6xl font-medium text-gray-900 whitespace-nowrap dark:text-white">
                Nota:
              </th>
              <td className="px-8 py-6 text-6xl" key={`recommendation-${recomendacion}`}>
                {recomendacion}
              </td>
            </tr>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th scope="row" className="px-8 py-6 text-6xl font-medium text-gray-900 whitespace-nowrap dark:text-white">
                Fecha:
              </th>
              <td className="px-8 py-6 text-6xl" key={`fechaPrueba-${fechaPrueba}`}>
                {fechaPrueba}
              </td>
            </tr>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th scope="row" className="px-8 py-6 text-6xl font-medium text-gray-900 whitespace-nowrap dark:text-white">
                Serie del transformador:
              </th>
              <td className="px-8 py-6 text-6xl" key={`serieTransformador-${salida}`}>
                {salida}
              </td>
            </tr>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th scope="row" className="px-8 py-6 text-6xl font-medium text-gray-900 whitespace-nowrap dark:text-white">
                Edad:
              </th>
              <td className="px-8 py-6 text-6xl" key={`salidaEdad-${salidaEdad}`}>
                {salidaEdad}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  
   
  );
}
export default Peticion;
